<template>
<b-overlay :show="loading">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(storeData)" @reset.prevent="reset">
            <b-row style="
                display: flex;
                flex-wrap: wrap;"
            >
                <b-col lg="12" sm="12">
                    <iq-card>
                        <b-row class="m-2">
                            <b-col md="6">
                                <ValidationProvider name="Source/Scheme" vid="source_scheme">
                                    <b-form-group class="row" label-cols-sm="12" label-for="source_scheme" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                            {{$t('certify_form.source_scheme')}}
                                        </template>
                                        <b-form-select
                                            v-if="appData.scheme_id"
                                            plain
                                            v-model="appData.scheme_id"
                                            id="source_scheme"
                                            :options="schemeList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            disabled
                                        >
                                        </b-form-select>
                                        <b-form-input
                                            v-if="appData.mouja_scheme_name"
                                            readonly
                                            type="text"
                                            v-model="appData.mouja_scheme_name"
                                            id="total_packet"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="Seed Name">
                                    <b-form-group class="row" label-cols-sm="12" label-for="seed_name_id" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('research_seed.seed_name')}}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="appData.seed_name_id"
                                            :options="seedNameList"
                                            id="seed_name_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            disabled
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="Seed Variety">
                                    <b-form-group class="row" label-cols-sm="12" label-for="seed_variety_id" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('external_research.seed_variety')}}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="appData.seed_variety_id"
                                            :options="varietyList"
                                            id="seed_variety_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            disabled
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="Seed Class" vid="seed_class_id" rules="required">
                                <b-form-group class="row" label-cols-sm="12" label-for="seed_class_id" slot-scope="{ valid, errors }">
                                    <template v-slot:label>
                                    {{$t('external_research.seed_class')}}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="appData.seed_class_id"
                                        :options="seedClassList"
                                        id="seed_class_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        disabled
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="Total seed amount" vid="total_seed_amount" rules="required">
                                    <b-form-group class="row" label-cols-sm="12" label-for="total_seed_amount" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.total_seed_amount')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            readonly
                                            type="text"
                                            v-model="appData.total_seed_amount"
                                            id="total_seed_amount"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="Total Packets">
                                    <b-form-group class="row" label-cols-sm="12" label-for="total_packet" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.total_packet')}}
                                        </template>
                                        <b-form-input
                                            readonly
                                            type="text"
                                            v-model="lot_main.total_packet"
                                            id="total_packet"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </iq-card>
                    <iq-card v-for="(item, index) in lot_main.lot_offer" :key="index">
                        <b-row class="m-2">
                            <b-col md="6">
                                <ValidationProvider name="Lot no" :vid="'lot_no'+index" rules="required:min_value:1">
                                    <b-form-group class="row" label-cols-sm="12" :label-for="'lot_no'+index" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{$t('certify_form.lot_no')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="number"
                                            v-model="item.lot_no"
                                            :id="'lot_no'+index"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6" class="d-flex">
                                <ValidationProvider name="Seed amount" class="width" :vid="'seed_amount'+index" rules="required">
                                    <b-form-group class="row" label-cols-sm="12" :label-for="'seed_amount'+index" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                            {{$t('certify_form.seed_amount')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            style="margin-right: -25px !important;width: 100% !important"
                                            type="number"
                                            @input="calculate()"
                                            v-model="item.seed_amount"
                                            :id="'seed_amount'+index"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <div v-for="(lot, i) in item.lot_offer_details" :key="i" class="lot-details">
                                <b-col md="6">
                                    <ValidationProvider name="Packet select" :vid="'packet_select'+i" rules="required|min_value:1">
                                        <b-form-group class="row" label-cols-sm="12" :label-for="'packet_select'+i" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{$t('certify_form.packet_select')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                v-model="lot.packet_select"
                                                :options="lot.seedPacket"
                                                :id="'packet_select'+i"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first v-if="lot.seedPacket[0]">
                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6" class="d-flex">
                                    <ValidationProvider name="Demand packet" class="width" :vid="'demand_packet_number'+i" rules="required|min_value:1">
                                        <b-form-group label-cols-sm="12" :label-for="'demand_packet_number'+i" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{$t('certify_form.demand_packet_number')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                style="margin-right: -25px !important;width: 100% !important"
                                                type="number"
                                                @input="lotCalculate(index)"
                                                v-model="lot.demand_packet"
                                                :id="'demand_packet_number'+i"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <div style="width: 19% !important;margin-top: 35px;">
                                        <b-badge v-if="item.lot_offer_details.length-1 === i" title="Add Packet" class="badge badge-success badge-btn ml-1 mr-1" @click.prevent="addMoreItem(index)"><i class="ri-add-line m-0"></i></b-badge>
                                        <b-badge v-if="item.lot_offer_details.length-1 === i && i > 0" title="Remove Packet" class="badge badge-danger badge-btn" @click="removeItem(index, i)"><i class="ri-delete-bin-line m-0"></i></b-badge>
                                    </div>
                                </b-col>
                            </div>
                            <b-col md="12" class="mb-3">
                                <b-button v-if="lot_main.lot_offer.length-1 === index" title="Add Lot" class="float-right btn btn-success btn-sm ml-1 mr-1" @click.prevent="addMore()">{{$t('globalTrans.add_more')}}<i class="ri-add-line m-0"></i></b-button>
                                <b-button v-if="lot_main.lot_offer.length-1 === index && index > 0" title="Remove Lot" class="float-right btn btn-danger btn-sm" @click="remove(index)">Remove<i class="ri-delete-bin-line m-0"></i></b-button>
                            </b-col>
                        </b-row>
                    </iq-card>
                </b-col>
            </b-row>
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
            </div>
        </b-form>
    </ValidationObserver>
</b-overlay>

</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { storeLotOffer, getAppData } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatpickr from 'flatpickr'
export default {
    props: ['id', 'item'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            saveBtnName: this.item ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            loading: false,
            appData: {
                scheme_id: '',
                mouja_scheme_name: '',
                mouja_scheme_name_bn: '',
                seed_name_id: 0,
                seed_class_id: 0,
                seed_varity_id: 0,
                total_seed_amount: ''
            },
            lot_main: {
                general_info_id: 0,
                total_seed_amount: '',
                total_packet: 0,
                lot_offer: [
                    {
                        general_info_id: this.id,
                        lot_no: '',
                        seed_amount: '',
                        lot_offer_details: [
                            {
                                general_info_id: this.id,
                                packet_select: 0,
                                demand_packet: 0,
                                seedPacket: this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedPacketList
                            }
                        ]
                    }
                ]
            }
        }
    },
    created () {
        if (this.id) {
            this.getApplicationData()
        }
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    computed: {
        schemeList () {
            const list = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.schemeList
            return list.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        varietyList () {
            const list = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedVarietyList
            return list.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        seedClassList: function () {
            const classes = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedClassNameList.filter(item => item.status === 0)
            return classes.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        seedNameList: function () {
            const seedNameList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedNameList.filter(seed => seed.status === 0)
            return seedNameList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        }
    },
    methods: {
        calculate () {
            let total = 0
            this.lot_main.lot_offer.map(element => {
                total += parseInt(element.seed_amount)
                if (parseInt(this.appData.total_seed_amount) < total) {
                    window.vm.$swal({
                        title: this.$t('certify_form.insufficient_amount'),
                        confirmButtonText: window.vm.$t('globalTrans.ok')
                    })
                    element.seed_amount = ''
                }
            })
        },
        lotCalculate (index) {
            let p = 0
            let lotPackets = 0
            this.lot_main.lot_offer[index].lot_offer_details.map(item => {
                const packet = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedPacketList.find(packet => packet.status === 0 && packet.value === parseInt(item.packet_select))
                p += packet !== undefined ? parseInt(packet.text_en) : 0 * item.demand_packet !== undefined ? parseInt(item.demand_packet) : 0
                if (p > parseInt(this.lot_main.lot_offer[index].seed_amount)) {
                    window.vm.$swal({
                        title: this.$t('certify_form.insufficient_amount'),
                        confirmButtonText: window.vm.$t('globalTrans.ok')
                    })
                    item.demand_packet = 0
                }
            })
            this.lot_main.lot_offer.map(item => {
                item.lot_offer_details.map(ele => {
                    lotPackets += parseInt(ele.demand_packet)
                })
            })
            this.lot_main.total_packet = lotPackets || 0
        },
        getApplicationData () {
            this.loading = true
            RestApi.getData(agriResearchServiceBaseUrl, `${getAppData}/${this.id}`).then(response => {
                if (response.success) {
                    this.loading = false
                    this.appData = response.data
                    this.appData.scheme_id = response.data.scheme_id
                    if (this.$i18n.locale === 'en') {
                        this.appData.mouja_scheme_name = response.data.mouja_scheme_name
                    } else {
                        this.appData.mouja_scheme_name_bn = response.data.mouja_scheme_name_bn
                    }
                    this.lot_main.general_info_id = response.data.id
                    this.lot_main = response.data.lot_main ? response.data.lot_main : this.lot_main
                    const packets = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedPacketList
                    let lotPackets = 0
                    this.lot_main.lot_offer.map(item => {
                        item.lot_offer_details.map(ele => {
                            ele.seedPacket = packets
                            lotPackets += parseInt(ele.demand_packet)
                        })
                    })
                    this.lot_main.total_packet = lotPackets || 0
                }
            })
        },
        addMore () {
            const packets = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedPacketList
            this.lot_main.lot_offer.push({
                general_info_id: this.id,
                lot_no: '',
                seed_amount: '',
                lot_offer_details: [
                    {
                        general_info_id: this.id,
                        packet_select: 0,
                        demand_packet: 0,
                        seedPacket: packets
                    }
                ]
            })
        },
        remove (index) {
            this.lot_main.lot_offer.splice(index, 1)
            let lotPackets = 0
            this.lot_main.lot_offer.map(item => {
                item.lot_offer_details.map(ele => {
                    lotPackets += parseInt(ele.demand_packet)
                })
            })
            this.lot_main.total_packet = lotPackets || 0
        },
        addMoreItem (index) {
            const packets = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedPacketList
            this.lot_main.lot_offer.map((item, key) => {
                if (index === key) {
                    item.lot_offer_details.push({
                        general_info_id: this.id,
                        packet_select: 0,
                        demand_packet: 0,
                        seedPacket: packets
                    })
                }
            })
        },
        removeItem (index, i) {
            this.lot_main.lot_offer.forEach((element, key) => {
                if (index === key) {
                    element.lot_offer_details.splice(i, 1)
                }
            })
            this.lotCalculate(index)
        },
        storeData () {
            this.lot_main.total_seed_amount = this.appData.total_seed_amount
            this.loading = true
            // this.$store.dispatch('mutateCommonProperties', { loading: true })
            RestApi.postData(agriResearchServiceBaseUrl, storeLotOffer, this.lot_main).then(response => {
                if (response.success) {
                    this.loading = false
                    this.$bvModal.hide('modal-1')
                    this.$toast.success({
                        title: 'Success',
                        message: this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    window.location.reload()
                    // this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                }
            })
        }
    }
}
</script>
<style scoped>
    .width{
        width: 100% !important;
    }
    .lot-details{
        width: 100% !important;
        display: flex !important;
    }
    .badge-btn{
        margin-top: 5px !important;
        height: 22px !important;
        cursor: pointer;
    }
</style>
