<template>
    <b-container fluid>
        <b-row>
            <b-overlay :show="loading">
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <div class="text-center">
                            <h5 class="text-dark">{{ $t('certify_form.lot_offer') }}</h5>
                        </div>
                    </template>
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                            <b-row class="m-2">
                                <b-col md="6">
                                    <ValidationProvider name="Source/Scheme" vid="source_scheme"  rules="required">
                                        <b-form-group class="row" label-cols-sm="6" label-for="source_scheme" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{$t('certify_form.source_scheme')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                plain
                                                v-model="lot_offer.source_scheme"
                                                id="source_scheme"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="Lot no" vid="lot_no"  rules="required">
                                        <b-form-group class="row" label-cols-sm="6" label-for="lot_no" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{$t('certify_form.lot_no')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                disabled
                                                plain
                                                v-model="lot_offer.lot_no"
                                                id="lot_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="Seed amount" vid="seed_amount" rules="required">
                                        <b-form-group class="row" label-cols-sm="6" label-for="seed_amount" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{$t('certify_form.seed_amount')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                plain
                                                disabled
                                                v-model="lot_offer.seed_amount"
                                                id="seed_amount"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="Total tag number" vid="total_tag_number" rules="required">
                                        <b-form-group class="row" label-cols-sm="6" label-for="total_tag_number" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{$t('certify_form.total_tag_number')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                disabled
                                                plain
                                                v-model="lot_offer.total_tag"
                                                id="total_tag_number"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row class="m-2" v-for="(item, index) in lot_offer.lot_offer_details" :key="index">
                                <b-col md="6">
                                    <ValidationProvider name="Unit" vid="unit_id" rules="required">
                                        <b-form-group class="row" label-cols-sm="6" label-for="plot_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{$t('certify_form.unit_measurement')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                @input="getValue(item.unit_id, item)"
                                                v-model="item.unit_id"
                                                :options="unitList"
                                                id="unit_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                                <template v-slot:first>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="Total seed amount in lot" vid="total_seed_amount_of_lot" rules="required">
                                        <b-form-group class="row" label-cols-sm="6" label-for="total_seed_amount_of_lot" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{$t('certify_form.packet_select')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                @input="getPacketSelection(item.packet_select)"
                                                v-model="item.packet_select"
                                                :options="getSeedPacket(item.unit_id)"
                                                id="total_seed_amount_of_lot"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                                <template v-slot:first>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="Total seed amount in lot" vid="demand_packet_number" rules="required">
                                        <b-form-group label-cols-sm="6" label-for="demand_packet_number" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                                {{$t('certify_form.demand_packet_number')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="number"
                                                plain
                                                @input="calculate()"
                                                v-model="item.demand_packet"
                                                :id="'demand_packet_number'+index"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <div style="width: 15%;margin-left: 10px;">
                                    <button v-if="lot_offer.lot_offer_details.length > 1" class="btn btn-sm btn-danger mr-1" @click="remove(index)" type="button"><i class="fas fa-window-close mr-0"></i></button>
                                    <button type="button" class="btn btn-sm btn-success" @click="addMore(item)">
                                        <i class="fas fa-plus mr-0"></i>
                                    </button>
                                </div>
                            </b-row>
                            <div class="row">
                                <div class="col text-right">
                                    <b-button :disabled="btn" type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                        </b-form>
                    </ValidationObserver>
                </iq-card>
            </b-col>
            </b-overlay>
        </b-row>
    </b-container>
</template>
<script>
    import { core } from '@/config/pluginInit'
    import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
    import { storeLotOfferInfo, lotOfferInfoData } from '../../api/routes'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'

    export default {
        props: ['item'],
        components: {
            ValidationObserver, ValidationProvider
        },
        data () {
            return {
                data: [],
                btn: false,
                loading: false,
                saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
                lot_offer: {
                    lot_offer_id: 0,
                    source_scheme: '',
                    lot_no: '',
                    seed_amount: '',
                    total_tag: 0,
                    general_info_id: this.item.general_info_id,
                    lot_offer_details: [
                        {
                            unit_id: 0,
                            packet_select: 0,
                            demand_packet: '',
                            unitList: [],
                            seedPacket: []
                        }
                    ]
                },
                items: [
                    {
                        unit_id: 0,
                        packet_select: 0,
                        demand_packet: ''
                    }
                ],
                unitList: [],
                itemValue: 0
            }
        },
        created () {
            this.data = this.item
            this.lot_offer.source_scheme = this.item.source_scheme
            this.lot_offer.lot_no = this.item.lot_number
            this.loadData()
        },
        mounted () {
            core.index()
        },
        computed: {
            currentLocale () {
                return this.$i18n.locale
            }
        },
        methods: {
            Checker (type) {
                return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.locally_produced') : this.$t('request_testing_tag_without_field_certification_application.imported')
            },
            CheckRegister (type) {
                return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.registered_status') : this.$t('request_testing_tag_without_field_certification_application.un-registered_status')
            },
            addMore () {
                this.unitList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList
                this.lot_offer.lot_offer_details.push({
                    unit_id: 0,
                    packet_select: 0,
                    seedPacket: [],
                    demand_packet: ''
                })
            },
            remove (index) {
                this.lot_offer.lot_offer_details.splice(index, 1)
                this.calculate()
            },
            getValue (newVal, item) {
                item.seedPacket = this.getSeedPacket(newVal)
            },
            getPacketSelection (newVal) {
                const packet = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedPacketList.find(item => item.status === 0 && item.value === newVal)
                this.packetVal = packet.text_en
            },
            calculate () {
                let t = 0
                let p = 0
               this.lot_offer.lot_offer_details.forEach((element, index) => {
                   t += parseInt(element.demand_packet)
                   p += parseInt(this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedPacketList.find(item => item.value === element.packet_select).text_en) * element.demand_packet
               })
               if (this.lot_offer.seed_amount < p) {
                   window.vm.$swal({
                       title: this.$t('certify_form.insufficient_amount'),
                       confirmButtonText: window.vm.$t('globalTrans.ok')
                   })
                   this.btn = true
               } else {
                   this.btn = false
               }
                this.lot_offer.total_tag = t * 2
            },
            getSeedPacket (id) {
                const packets = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedPacketList.filter(item => item.status === 0 && item.unit_id === id)
                return packets.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
            },
            async loadData () {
                let result = null
                result = await RestApi.getData(agriResearchServiceBaseUrl, lotOfferInfoData + '/' + this.item.general_info_id)
                if (result.success) {
                    if (result.data) {
                        this.lot_offer = result.data
                        this.unitList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList
                        result.data.forEach((element, index) => {
                            this.lot_offer.lot_offer_details.seedPacket[index] = this.getSeedPacket(element.packet_select)
                        })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#D6E09B'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
              this.lot_offer.lot_offer_details[0].unitList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList
              }
            },
            async register () {
                this.loading = true
                let result = null
                result = await RestApi.postData(agriResearchServiceBaseUrl, storeLotOfferInfo, this.lot_offer)
                if (result.success) {
                    this.$bvModal.hide('modal-6')
                    this.$toast.success({
                        title: 'Success',
                        message: this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    this.$emit('listenerChild')
                   this.loading = false
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#D6E09B'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
                this.$emit('listenerChild')
                this.loading = false
            }
        }
    }

</script>
<style lang="scss">
    .details {
        h5 {
            color: green !important;
            font-size: 20px !important;
        }
        .table-bordered td:nth-child(2n+1) {
            font-weight: 700;
        }
    }
    .hidden_header {
        display: none
    }
    [dir=ltr][mode=light] .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 10px;
    }
</style>
